import EnvironmentService from "../environment/EnvironmentService";

let environmentService = new EnvironmentService();
let baseAPI = environmentService.getBaseAPI();
let serviceAPI = environmentService.getServiceAPI();
let serviceSocket = environmentService.getServiceSocket();
let strapiAPI = environmentService.getStrapiAPI();
const APIConfig = {
  base: baseAPI,
  microService: serviceAPI,
  safePay: environmentService.getSafePayUrl(),
  user: {
    getOTP: baseAPI + "/login/v2/otp",
    getMliOtp: serviceAPI + "/v1/mli/otp",
    getPlans: serviceAPI + "/v1/mli/{0}",
    // verifyOTPNEW: baseAPI + "/login/v4/otp",
    verifyOTP: baseAPI + "/login/v5/otp",
    generateToken: baseAPI + "/login/token",
    paytm: baseAPI + "/login/paytmregister",
    new: baseAPI + "/employees/new",
    getAll: baseAPI + "/employees/all",
    get: baseAPI + "/{0}",
    register: baseAPI + "/login/v3/register",
    verifyDocCode: baseAPI + "/docprofile/doctor?code={0}",
    setting: serviceAPI + "/v1/patients/{0}/settings",
    updateSettings: serviceAPI + "/v1/patients/{0}/settings",
    autoLogin: baseAPI + "/login/authorize?phoneNo={0}",
    userId: serviceAPI + "/v1/users/{0}",
    deviceDetails: serviceAPI + "/v1/messaging/devices/{0}",
    getPatientInfo: baseAPI + "/patients/{0}/gpfa",
    getUserInfo: baseAPI + "/patients/{0}/gpfcs",
    premiumStatus: serviceAPI + "/v1/patients/{0}",
    logOut: serviceAPI + "/v1/notifications/{0}/remove",
    expireToken: baseAPI + "/login/logout",
    autoLoginDigit: baseAPI + "/login/authorize?phoneNo={0}&idToken={1}",
    fetchTherapy: serviceAPI + "/v2/pa/assessments/questions/1",
    clientConfig: serviceAPI + "/v1/clientconfig/details",
    orgEmailVerify: serviceAPI + "/v1/users/email/otp",
    orgOtpVerify: serviceAPI + "/v1/users/email/verification",
    sourceonTentacle: baseAPI + "/login/sourceregister",
    token: baseAPI + "/login/token",
    createRequest: serviceAPI + "/v1/family/booking",
    bannerImages: strapiAPI + "/api/mli-offer-banners/freemium/{0}",
    rewardPointServices:
      strapiAPI + "/api/earn-more-reward-points/freemium/{0}",
    stepathonAbout: strapiAPI + "/api/mli-challanges/freemium/{0}",
    leaderBoard: serviceAPI + "/v1/mli/leaderBoard/{0}",
    reward: serviceAPI + "/v2/mli/rewards/{0}",
    rewardLedger:
      serviceAPI + "/v2/mli/rewards/{0}/{1}/ledger?page={2}&filter={3}",
    clientConfigUsingClientCode:
      serviceAPI + "/v1/clientconfig/client?clientCode={0}",
    feedback: serviceAPI + "/v1/feedback",
    consentStatus: serviceAPI + "/api/v1/consent/mli/{0}",
    saveConsent: serviceAPI + "/api/v1/consent/update",
  },
  events: {
    logEvent: serviceAPI + "/v1/events",
    notificationAnalytics: serviceAPI + "/v1/notifications/{0}/event",
  },
  report: {
    getByDate: serviceAPI + "/api/v2/report/{0}?date={1}",
    save: serviceAPI + "/api/v2/report",
  },
  fcm: {
    pushCredential: serviceAPI + "/v1/notifications",
  },
  system: {
    generalSettings: baseAPI + "/settings/generic",
  },

  doctor: {
    getAll: baseAPI + "/doctor/all",
    addNew: baseAPI + "/doctor/add",
    update: baseAPI + "/doctor/",
    activate: baseAPI + "/doctor/update/status",
    deactivate: baseAPI + "/doctor/update/status",
  },

  patient: {
    new: baseAPI + "/patients/new",
    getAll: baseAPI + "/patients/all",
    get: baseAPI + "/patients/{0}",
    updateParams: baseAPI + "/patients/",
    updateParamGroups: baseAPI + "/patients/{0}/groups",
    getTrackingInfo: baseAPI + "/patients/{0}/track?doctorId={1}",
    updateStatus: baseAPI + "/patients/{0}/update/status",
    uploadProfilePic: baseAPI + "/patients/{0}/profile/image",
    invite: baseAPI + "/patients/{0}/invite",
    updateEmail: baseAPI + "/patients/{0}/email",
    search: baseAPI + "/patients/search?q={0}&type={1}&paid={2}",
    requestCall: baseAPI + "/leads/request_call/{0}",
  },

  feedback: {
    getAll: baseAPI + "/feedback/all",
  },

  unit: {
    new: baseAPI + "/units/new",
    getAll: baseAPI + "/units/all",
    get: baseAPI + "/units/",
  },

  param: {
    new: baseAPI + "/params/new",
    getAll: baseAPI + "/params/all",
    get: baseAPI + "/params/",
    update: baseAPI + "/params/{0}/update",
    group: {
      new: baseAPI + "/params/groups/new",
      getAll: baseAPI + "/params/groups/all",
      get: baseAPI + "/params/groups/{0}",
      update: baseAPI + "/params/groups/{0}",
    },
  },
  chat: {
    connect: serviceAPI + "/v2/chat/users",
    get: serviceAPI + "/v2/chat/users/{0}",
    message: serviceAPI + "/v1/messaging/messages",
    putMessage: serviceAPI + "/v1/messaging/messages/{0}",
    linkpreview: serviceAPI + `/v1/linkpreview/get?url={0}`,
  },
  goal: {
    master: {
      new: baseAPI + "/goals/master/new",
      getAll: baseAPI + "/goals/master/all",
      get: baseAPI + "/goals/master/",
      update: baseAPI + "/goals/master/",
    },
    new: baseAPI + "/goals/new",
    getAll: baseAPI + "/goals/all",
    get: baseAPI + "/goals/",
    update: baseAPI + "/goals/",
  },

  task: {
    master: {
      new: baseAPI + "/tasks/master/new",
      getAll: baseAPI + "/tasks/master/all",
      get: baseAPI + "/tasks/master/",
      update: baseAPI + "/tasks/master/",
    },
    recurring: {
      new: baseAPI + "/tasks/recurring/new",
      update: baseAPI + "/tasks/recurring/",
    },
    new: baseAPI + "/tasks/new",
    getAll: baseAPI + "/tasks/all",
    get: baseAPI + "/tasks/",
    update: baseAPI + "/tasks/",
  },

  communication: {
    send: baseAPI + "/communication/send",
  },

  paramValue: {
    new: baseAPI + "/params/value/new",
    getAllByPatient: baseAPI + "/params/value/patients/{0}?dueDate={1}",
    get: baseAPI + "/params/",
    update: baseAPI + "/params/value/",
  },

  role: {
    new: baseAPI + "/roles/new",
    getAll: baseAPI + "/roles/all",
    update: baseAPI + "/roles/",
  },

  permission: {
    getAll: baseAPI + "/permissions/all",
  },

  feed: {
    newInformationCard: baseAPI + "/feeds/information/new",
    getInformationCards: baseAPI + "/feeds/information/all",
    getInformationCard: baseAPI + "/feeds/information/{0}",
  },

  checklistMaster: {
    new: baseAPI + "/checklists/master/new",
    get: baseAPI + "/checklists/master/{0}",
    getAll: baseAPI + "/checklists/master/all",
    update: baseAPI + "/checklists/master/{0}",
  },

  checklist: {
    new: baseAPI + "/checklists/new",
    get: baseAPI + "/checklists/{0}",
    getAll: baseAPI + "/checklists/all",
    update: baseAPI + "/checklists/{0}",
    getByPatientDoctor:
      baseAPI + "/checklists/patient-doctor?patientId={0}&doctorId={1}",
  },

  checklistValue: {
    new: baseAPI + "/checklists/values/new",
    get: baseAPI + "/checklists/values/{0}",
    getAll: baseAPI + "/checklists/values/all",
    update: baseAPI + "/checklists/values/{0}",
    getByPatientDoctor:
      baseAPI +
      "/checklists/values/patient-doctor?patientId={0}&doctorId={1}&dueDate={2}",
  },

  careTeam: {
    new: baseAPI + "/careteams/new",
    get: baseAPI + "/careteams/{0}",
    getAll: baseAPI + "/careteams/all",
    update: baseAPI + "/careteams/{0}",
    getByPatient: baseAPI + "/careteams/patients/{0}",
    doctoradd: baseAPI + "/patients/{0}/referrer",
  },

  call: {
    new: baseAPI + "/calls/new",
    get: baseAPI + "/calls/{0}",
    getDoctorName: baseAPI + "/calls/doctor/{0}",
    getAll: baseAPI + "/calls/all",
    update: baseAPI + "/calls/{0}",
    getCallPlan: baseAPI + "/calls/callplan?caller={0}&callee={1}&dueDate={2}",
    getCallPlanByCallee:
      baseAPI + "/calls/callplan/callee?callee={0}&fromDate={1}&toDate={2}",
    getDoctorNamesAndDueDates:
      baseAPI +
      "/calls/all_callers_with_due_date?callee={0}&fromDate={1}&toDate={2}",
  },

  callNote: {
    new: baseAPI + "/calls/notes/new",
    get: baseAPI + "/calls/notes/{0}",
    getAll: baseAPI + "/calls/notes/all",
    update: baseAPI + "/calls/notes/{0}",
    disable: baseAPI + "/calls/notes/disable/{0}",
    getAllByCall: baseAPI + "/calls/notes/calls/{0}",
    detailedNote: baseAPI + "/calls/notes/details/{0}",
    directMessage: serviceAPI + "/api/v1/chat/user/{0}/message",
    getUserId: serviceAPI + "/api/v1/user/patient/{0}",
  },

  callToDo: {
    new: baseAPI + "/calls/todos/new",
    get: baseAPI + "/calls/todos/{0}",
    getAll: baseAPI + "/calls/todos/all",
    update: baseAPI + "/calls/todos/{0}",
    getAllByCall: baseAPI + "/calls/todos/calls/{0}",
  },

  medication: {
    new: baseAPI + "/medications/new",
    get: baseAPI + "/medications/{0}",
    getAll: baseAPI + "/medications/all",
    update: baseAPI + "/medications/{0}",
  },

  chiefComplaint: {
    new: baseAPI + "/chief-complaints/new",
    get: baseAPI + "/chief-complaints/{0}",
    getAll: baseAPI + "/chief-complaints/all",
    update: baseAPI + "/chief-complaints/{0}",
  },

  document: {
    upload: baseAPI + "/documents/upload",
    uploadDoc: serviceAPI + "/v1/documents",
    get: baseAPI + "/documents/{0}",
    getDoc: serviceAPI + "/v1/documents/{0}",
    getAll: baseAPI + "/documents/all",
    getAllFolders: baseAPI + "/documents/folders/all",
    update: baseAPI + "/documents/{0}",
    updateType: baseAPI + "/documents/{0}/update-type/{1}",
    updateNameAndDescription:
      baseAPI + "/documents/{0}/update-name-and-description",
    getByPatientDoc: serviceAPI + "/v1/documents/patients/{0}?groupBy=type",
    getByPatient: baseAPI + "/documents/patients/{0}",
    tokenGenerate: baseAPI + "/documents/token/generate",
    getMedicine: serviceAPI + "/api/v1/medicine/offers",
    postMedicine: serviceAPI + "/api/v1/medicine/orders",
  },

  cuisine: {
    new: baseAPI + "/cuisines/new",
    get: baseAPI + "/cuisines",
    getAll: baseAPI + "/cuisines/all",
    update: baseAPI + "/cuisines/",
  },

  medicalCondition: {
    new: baseAPI + "/medical_conditions/new",
    get: baseAPI + "/medical_conditions",
    getAll: baseAPI + "/medical_conditions/all",
    update: baseAPI + "/medical_conditions/",
  },

  constituent: {
    new: baseAPI + "/constituents/new",
    get: baseAPI + "/constituents",
    getAll: baseAPI + "/constituents/all",
    update: baseAPI + "/constituents/",
  },

  recipe: {
    new: baseAPI + "/recipes/new",
    get: baseAPI + "/recipes",
    getAll: baseAPI + "/recipes/all",
    update: baseAPI + "/recipes/",
  },

  dish: {
    master: {
      new: baseAPI + "/dish_master/new",
      getAll: baseAPI + "/dish_master/all",
      get: baseAPI + "/dish_master",
      update: baseAPI + "/dish_master/",
    },
    new: baseAPI + "/dishes/new",
    getAll: baseAPI + "/dishes/all",
    get: baseAPI + "/dishes",
    update: baseAPI + "/dishes/",
  },

  dishConstituent: {
    new: baseAPI + "/dish_constituents/new",
    get: baseAPI + "/dish_constituents",
    getAll: baseAPI + "/dish_constituents/all",
    update: baseAPI + "/dish_constituents/",
  },

  dishMedicalCondition: {
    new: baseAPI + "/dish_medical_conditions/new",
    get: baseAPI + "/dish_medical_conditions",
    getAll: baseAPI + "/dish_medical_conditions/all",
    update: baseAPI + "/dish_medical_conditions/",
  },

  salesPatient: {
    getAll: baseAPI + "/sales/patients/all?pageNumber={0}&pageSize={1}",
    updateProfile: baseAPI + "/sales/patients/{0}/profile",
  },

  onboardingDetail: {
    new: baseAPI + "/onboarding-details/new",
    get: baseAPI + "/onboarding-details/{0}",
    getAll: baseAPI + "/onboarding-details/all",
    update: baseAPI + "/onboarding-details/{0}",
  },

  testReading: {
    update: serviceAPI + "/api/v3/vitals/{0}?forDate={1}",
    get: serviceAPI + "/api/v2/vitals/{0}?forDate={1}",
    chart: {
      getVitals: serviceAPI + "/api/v2/vitalchart/{0}/v2/vitals",
      getChart:
        serviceAPI + "/api/v2/vitalchart/{0}/paramgroup/{1}?forDate={2}",
    },
  },
  tracker: {
    dailyTargets:
      serviceAPI + "/api/v1/sleep-step-tracker/targets/{0}?date={1}",
    chart: {
      getSteps:
        serviceAPI + "/api/v1/sleep-step-tracker/steps/{0}?from={1}&to={2}",
      getSleep:
        serviceAPI + "/api/v1/sleep-step-tracker/sleep/{0}?from={1}&to={2}",
    },
    sync: {
      lastSync: serviceAPI + "/api/v1/sleep-step-tracker/last-synced/{0}",
      syncSleep: serviceAPI + "/v1/healthkit/sleepSync",
      syncSteps: serviceAPI + "/api/v1/sleep-step-tracker/bulk-save/steps/{0}",
    },
    sendData: {
      sleep: serviceAPI + "/api/v1/sleep-step-tracker/sleep",
      steps: serviceAPI + "/api/v1/sleep-step-tracker/steps",
    },
  },

  careManagerService: {
    get: serviceAPI + "/api/v1/chat/user/{0}/token",
    getAll: serviceAPI + "/api/v1/chat/users/all",
    getAllActivity: serviceAPI + "/api/v1/chat/user/activity",
    getAllForAsync: serviceAPI + "/api/v1/chat/ws/users",
    update: serviceAPI + "/api/v1/chat/user/{0}/token/generate",
    // update: serviceAPI + '/api/v1/chat/user/{0}',
  },

  careManagerSocket: {
    getAll: serviceSocket + "/api/v1/chat/users",
  },

  routineReporting: {
    getAll: serviceAPI + "/api/v1/report/{0}",
    getWithDateSpecified:
      serviceAPI + "/api/v1/report/data/{0}?date={1}&format=rtf",
  },

  meditation: {
    getAll: baseAPI + "/meditations/all",
    get: baseAPI + "/meditations/{0}",
    getMeditation: serviceAPI + "/v1/meditations?pid={0}",
    recommendedMeditation: serviceAPI + "/v1/meditations/{0}/suggestions",
  },

  progressNote: {
    new: serviceAPI + "/api/v1/progress_notes",
    getAll: serviceAPI + "/api/v1/progress_notes/{0}?interval={1}&number={2}",
    update: serviceAPI + "/api/v1/progress_notes/{0}",
  },

  nutritionNote: {
    new: serviceAPI + "/api/v1/nutrition_notes",
    getAll: serviceAPI + "/api/v1/nutrition_notes/{0}?interval={1}&number={2}",
    update: serviceAPI + "/api/v1/nutrition_notes/{0}",
  },

  detailedAssessment: {
    get: serviceAPI + "/api/v1/user/da/{0}",
    update: serviceAPI + "/api/v1/user/da/{0}",
  },

  newProgressNote: {
    new: serviceAPI + "/api/v1/progress_notes",
    getAll:
      serviceAPI +
      '/api/v1/progress_notes?where={"patientId":{0}}&embedded={"comments":1}&max_results={1}&page={2}&sort=[("_id",-1)]',
    getAllWithType:
      serviceAPI +
      '/api/v1/progress_notes?where={"patientId":{0},"tag":{3}}&embedded={"comments":1}&max_results={1}&page={2}&sort=[("_id",-1)]',
    update: serviceAPI + "/api/v1/progress_notes/{0}",
  },

  progressNoteComment: {
    new: serviceAPI + "/api/v1/comments",
    update: serviceAPI + "/api/v1/comments/{0}",
  },

  assessment: {
    getPA: serviceAPI + "/v2/pa/assessments/{0}",
    submitPA: serviceAPI + "/v2/pa/assessments/{0}",
    previousQuestion: serviceAPI + "/v2/pa/assessments/{0}/previous?from={1}",
    nextQuestion: serviceAPI + "/v2/pa/assessments/{0}/next?from={1}",
    getoldPA: serviceAPI + "/v1/pa/assessments/{0}",
  },

  // healthPlan: {
  //   getPatientHealthPlan: serviceAPI + "/api/v2/health-plan/{0}",
  //   updatePatientHealthPlan: serviceAPI + "/api/v2/health-plan/{0}",
  //   getPatientGoals: serviceAPI + "/api/v2/goals/{0}/short-term?page={1}",
  //   createPatientGoal: serviceAPI + "/api/v2/goals",
  //   updatePatientGoal: serviceAPI + "/api/v2/goals/{0}",
  //   getItems: serviceAPI + "/api/v2/health-plan/master?page={0}",
  //   getOptions: serviceAPI + "/api/v2/health-plan/master/options?page={0}",
  //   getGoals: serviceAPI + "/api/v2/goals/master?page={0}"
  // },

  // healthGoals: {
  //   getLongTermGoals: serviceAPI + "/api/v2/goals/{0}/long-term",
  //   getShortTermGoals: serviceAPI + "/api/v2/goals/{0}/short-term",
  //   getProgress: serviceAPI + "/api/v2/progress/{0}",
  // },
  healthGoals: {
    getLongTermGoals: serviceAPI + "/v1/goals/{0}/long-terms",
    getShortTermGoals: serviceAPI + "/v1/goals/{0}/short-terms",
    getProgress: serviceAPI + "/api/v2/progress/{0}",
  },

  triviaMessage: {
    get: serviceAPI + "/statemachine/scheduler/dynamic/messages/list",
    new: serviceAPI + "/statemachine/scheduler/dynamic/message/create",
    update: serviceAPI + "/statemachine/scheduler/dynamic/message/{0}/update",
  },

  tasksReport: {
    get: serviceAPI + '/api/v1/tasks/report?where={"patientId":{0}}',
  },

  healthGoalsDashboard: {
    get: serviceAPI + "/api/v2/goals/master?page={0}",
    new: serviceAPI + "/api/v2/goals/master",
    update: serviceAPI + "/api/v2/goals/master/{0}",
  },

  healthTasksDashboard: {
    get: serviceAPI + "/api/v2/tasks/{0}",
    update: serviceAPI + "/api/v2/tasks/report",
    // new: serviceAPI + "/api/v2/tasks/master",
    // update: serviceAPI + "/api/v2/tasks/master/{0}"
  },

  medicineOrders: {
    get: serviceAPI + "/api/v1/medicine/orders?page={0}",
    update: serviceAPI + "/api/v1/medicine/orders/{0}",
  },

  escalation: {
    getAll:
      serviceAPI + '/api/v1/vitals/escalations?page={0}&sort=[("_id",-1)]',
    update: serviceAPI + "/api/v1/vitals/escalations/{0}",
    updateStatus: serviceAPI + "/api/v1/vitals/escalations/status/update",
  },

  newAppFeatureDashboard: {
    get: serviceAPI + "/api/v1/release/version/features",
    new: serviceAPI + "/api/v1/release/version/features",
    update: serviceAPI + "/api/v1/release/version/features/{0}",
  },

  fileService: {
    new: serviceAPI + "/api/v1/file",
    get: serviceAPI + "/api/v1/file/{0}",
  },

  healthPlanDashboard: {
    get: serviceAPI + "/api/v2/health-plan/master?page={0}",
    getOptions: serviceAPI + "/api/v2/health-plan/master/options?page={0}",
    newOption: serviceAPI + "/api/v2/health-plan/master/options",
    updateOption: serviceAPI + "/api/v2/health-plan/master/options/{0}",
    new: serviceAPI + "/api/v2/health-plan/master",
    update: serviceAPI + "/api/v2/health-plan/master/{0}",
    csvUpload: serviceAPI + "/api/v2/health-plan/master/upload",
  },
  patientsData: {
    updatePatientData: baseAPI + "/patients/{0}/profile",
    getAddress: serviceAPI + "/api/v1/address/pincode/{0}",
  },
  healthPlan: {
    getPlan: serviceAPI + "/v4/hp/{0}",
    getCategory: serviceAPI + "/v4/category",
    getProgress: serviceAPI + "/api/v2/health-plan/{0}/progress",
    getFoodList: serviceAPI + "/v1/foodlist/{0}?filter={1}",
  },
  payment: {
    initiate: serviceAPI + "/v1/paytm/transactions/initiate/{0}",
    status: serviceAPI + "/v1/paytm/transactions/status/{0}",
  },
  doctorNotes: {
    get: baseAPI + "/doctor/notes/details/{0}",
  },

  freemium: {
    zylaTV: serviceAPI + "/api/v1/zylayoutube/getAll?count={0}",

    blogs: serviceAPI + "/api/v1/zylaBlog?count={0}",
    loginImgs: strapiAPI + "/api/walkthroughs/v1/freemium",
    homepage: strapiAPI + "/api/home/v1/{0}",
    recipe: strapiAPI + "/api/recipes/v1/freemium/{0}",
    yoga: {
      upcoming: strapiAPI + "/api/yogas/v1/freemium/upcoming/{0}",
      past: strapiAPI + "/api/yogas/v1/freemium/past/{0}",
    },
  },
  premium: {
    testimonials: strapiAPI + "/api/app-testimonials/freemium/{0}",
    outcomes: strapiAPI + "/api/outcomes/freemium/{0}",
    blogs: strapiAPI + "/api/blogs/freemium/{0}",
    videos: strapiAPI + "/api/videos/freemium/{0}",
    plans: serviceAPI + "/v1/plans?pid={0}",
    allPlans: serviceAPI + "/v1/plans",
  },
  latestprofile: {
    getProfile: serviceAPI + "/v1/patients/{0}",
    userProfile: serviceAPI + "/v1/users/{0}",
  },
  razorPay: {
    orders: serviceAPI + "/api/v1/payment/{0}/rzp/order",
  },

  digit: {
    plan: serviceAPI + "/v1/plans/assignments/patients/{0}",
  },
  shareInfo: {
    sharing: serviceAPI + "/v1/sharing",
    sharingImg: serviceAPI + "/v1/sharing/shareImg",
  },
  device: {
    new: serviceAPI + "/v1/med/device/{0}",
    getLatest: serviceAPI + "/v1/med/device/sync/seq/{0}/device/{1}",
    saveReadings: serviceAPI + "/api/v3/vitals/{0}/device/sync",
  },
  familyPlan: {
    primaryAddress: serviceAPI + "/api/v1/address",
    getFamilyMembers: serviceAPI + "/v1/family",
    createMemberMinor: serviceAPI + "/v1/family/minor",
    getAllRelation: serviceAPI + "/v1/family/relations",
    getFamilyOTP: serviceAPI + "/v1/family/otp/generate",
    verifyFamilyOTP: serviceAPI + "/v1/family/otp/validate",
    familyAddress: serviceAPI + "/v1/family/{0}/address",
    modifyFamilyAddress: serviceAPI + "/v1/family/{0}/address/{1}",
  },
  navigoDashboard: {
    createAppointment: serviceAPI + "/api/v2/navigo/appointment",
    appointmentByPatient: serviceAPI + "/api/v1/navigo/appointment/patient/{0}",
    allAppointments: serviceAPI + "/api/v2/navigo/appointment/patient/{0}",
    getAllAppointmentByStatus:
      serviceAPI +
      "/api/v1/navigo/appointment?status={0}&limit={1}&offset={2}&cc={3}",
    getTimeSlots:
      serviceAPI +
      "/api/v1/navigo/appointment/available-slots?sid={0}&date={1}",
    generalTimeSlot: serviceAPI + "/api/v2/navigo/appointment/available-slots",
    getAllServices: serviceAPI + "/api/v1/navigo/service",

    getServiceByClientCode: serviceAPI + "/api/v1/navigo/service/client?cc={0}",

    updateAppointmentStatus: serviceAPI + "/api/v1/navigo/appointment/status",
    rescheduleAppointments:
      serviceAPI + "/api/v1/navigo/appointment/reschedule",

    getServicesByPatientId: serviceAPI + "/api/v2/navigo/service/patient/{0}",
    redcliffTimeSlot: serviceAPI + "/v1/rc/slots",
    redcliffBooking: serviceAPI + "/v1/rc/booking",
    checkAvailability: serviceAPI + "/v1/ms/booking/service/check",
    generateNavigoOrderId:
      serviceAPI + "/api/v1/navigo/service/generatepaymentid",
    checkAppointCreateStatus:
      serviceAPI + "/api/v2/navigo/appointment/status/{0}",
  },
  wallet: {
    getWallet: serviceAPI + "/v1/wallet",
    walletTransactions:
      serviceAPI + "/v1/wallet/{0}/transactions?size={1}&range={2}",
    refreshBalance: serviceAPI + "/v1/wallet/refresh",
    singleTransaction: serviceAPI + "/v1/wallet/{0}/transactions",
    walletFAQs: serviceAPI + "/v1/wallet/faqs",
    orderDetails: serviceAPI + "/v1/zpay/orders/{0}",
    createOrder: serviceAPI + "/api/v1/payment/zpay/order",
  },
};

export default APIConfig;
